/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import '~@ionic/angular/css/display.css';

/* Optional CSS utils that can be commented out */
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";

button.html-link-button {
  background: transparent;
  color: var(--ion-color-primary);
  font-size: 1rem;
  padding: 0;
  margin: 0;
  text-decoration: underline;
}

ion-header ion-toolbar {
  --background: var(--ion-color-primary);
  --color: var(--ion-color-primary-contrast);
}

.break-lines {
  line-height: 1.25;
  overflow: visible !important;
  white-space: normal !important;
}

.button-link {
  text-transform: none;

  &.inline {
    height: unset;
    letter-spacing: normal;
    font-size: 1em;
    line-height: 1.25;
    margin-top: 0;
    margin-bottom: 0;
    margin-inline-start: 0;
    margin-inline-end: 0;
    --padding-start: 0;
    --padding-end: 0;
    --padding-top: 0;
    --padding-right: 0;
    --padding-bottom: 0;
    --padding-left: 0;
  }
}

.flex {
  display: flex !important;

  &.wrap {
    flex-wrap: wrap;
  }
}

.header-translucent {
  opacity: 0;
}

.warning {
  color: var(--ion-color-danger) !important;
  font-size: 14px !important;
}

